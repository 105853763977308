<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <v-select
        v-if="types"
        outlined
        hide-details
        v-model="content.ChannelListType"
        :items="types"
        item-text="Name"
        item-value="ID"
        :label="$t('dtouch.activities.types', locale)"
        @change="handleChangeIsModified"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import api from '@/services/api'
export default {
  name: 'Form33ChannelList',
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    types: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.getTypes()
    this.prepareContent()
  },
  methods: {
    getTypes () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/channel-list-types`)
        .then(response => {
          this.types = response
        })
    },
    prepareContent () {
      this.content = this.editedItem
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>

